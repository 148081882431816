h3 {
  color: black;
}

.lecture-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.lecture-item {
  background-color: #f5f5f5;
  padding: 15px;
  border-radius: 5px;
}

.tag-button {
  padding: 5px 10px;
  border-radius: 15px;
  color: white;
  background-color: #e99012;
  font-size: 0.9em;
  text-decoration: none;
  cursor: pointer;
  transition: opacity 0.3s;
}

.tag-button:hover {
  opacity: 0.8;
}

.materials {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 10px;
}

.material-button {
  padding: 5px 10px;
  border-radius: 15px;
  color: white;
  font-size: 0.9em;
  text-decoration: none;
  cursor: pointer;
  transition: opacity 0.3s;
}

.material-button:hover {
  opacity: 0.8;
}
.blue { background-color: #007bff; }
.green { background-color: #28a745; }
.red { background-color: #dc3545; }
.cyan { background-color: #17a2b8; }
.yellow { background-color: #efb618; }

.tags {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 10px;
}

.tag {
  background-color: brown;
  color: black;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 0.9em;
}
