/* Custom styles for the homework page */


/* Homework item styles */
.border {
  border: none;
  background-color: #2d2d2d;  /* Slightly lighter than the background */
  margin-bottom: 1rem;
}

.rounded-lg {
  border-radius: 1rem;  /* Increased roundness */
}

/* Header styles */
.bg-gray-100 {
  background-color: transparent;
}

/* Content layout */
.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;  /* Increased padding for more indentation */
}

/* Title styles */
h3.text-lg {
  color: #ffffff;
  font-size: 1.2rem;
  margin: 0;
}

/* Icon and info styles */
.flex .flex {
  gap: 1rem;
}

/* Icon styles */
svg {
  color: #3498db;
}

/* Expanded content styles */
.p-4 > p {
  color: #cccccc;
  line-height: 1.6;
  padding: 0 1rem 1.0rem;  /* Added horizontal padding to match the header */
}

/* Link styles */
a {
  color: #3498db;
  transition: color 0.3s ease;
}

a:hover {
  color: #2980b9;
}
.w-4.h-4.template {
  padding: 0rem  0rem 0rem 1rem;
}

/* Responsive design */
@media (min-width: 641px) {
  .homework-header {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .info-container {
    margin-top: 0;
  }

  .homework-title {
    padding-right: 0; /* Remove padding on larger screens */
  }

  .expand-button {
    position: static; /* Reset position for desktop view */
    margin-left: 0rem;
  }
}

/* Expanded content styles */
.expanded-content {
  padding: 0 1.5rem 1.5rem;
}

.expanded-content p {
  color: #cccccc;
  line-height: 1.6;
  margin-bottom: 1rem;
}

/* Link styles */
a {
  color: #3498db;
  transition: color 0.3s ease;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
}

a:hover {
  color: #2980b9;
}

a svg {
  margin-right: 0.5rem;
}

/* Styling pro nadpisy uvnitř popisu úkolů */
.description-h2 {
  font-size: 18px;
  color: #333;
  margin-bottom: 10px;
}

/* Styling pro paragrafy */
.description {
  margin-left: 1rem;

}

#ukoly p {
  display: inline; /* Ensure paragraphs are block elements */
  color: #cccccc;
  line-height: 1.6;
  padding: 0 1rem 1.0rem;  /* Added horizontal padding to match the header */
  margin: 0 0 1rem;  /* Ensuring proper spacing */

}
.description.h3 {
  color: #ffffff;
}

/* Styling pro kódové bloky */
.description-code {
  background-color: #f7f7f7;
  border-radius: 0.3rem;
  padding: 0.1rem 0.1rem;
  font-family: monospace;
  color: #b075c9;
}

/* Styling pro odkazy */
.description-a {
  color: #0275d8;
  text-decoration: none;
}

.description-a:hover {
  text-decoration: underline;
}
